<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-end" (click)="mainMenuToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>

    <li [class.mega-menu]="true">
        <a [routerLink]="'/'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"  >Inicio</a>
    </li>
    
    <li [class.mega-menu]="true">
        <a [routerLink]="'/pages/contact'" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link" >Contacto</a>
    </li>

    </ul>
  </div>
</div>
